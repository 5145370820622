import React, { useEffect, useState } from "react"

import { withPrefix } from "gatsby"
import { Document, Page } from "react-pdf"

import Layout from "../components/layout"

const Catalogue = () => {
  const [showPdf, setShowPdf] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  useEffect(() => {
    setShowPdf(true)
  }, [])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  return (
    <Layout
      seo={{
        title: "Katalog Five Seasons",
      }}
    >
      <section className="subpage-top">
        <h1>Katalog Five Seasons</h1>
      </section>

      <section className="page-catalogue">
        <div className="container">
          <div className="page-catalogue__flipbook">
            {" "}
            <iframe
              src={withPrefix("/pdf-catalogue.html")}
              width="100%"
              height="100%"
              title="Katalog"
            ></iframe>
          </div>
        </div>

        <div className="container">
          <div className="page-catalogue__wrapper">
            {showPdf && (
              <Document
                className={"PDFDocument"}
                file={withPrefix("/5seasons_katalog_grupy.pdf")}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  className={"PDFPage PDFPageOne"}
                  pageNumber={pageNumber}
                />
              </Document>
            )}
          </div>
          <div className="page-catalogue__nav">
            <button
              className={pageNumber <= 1 ? "disabled" : ""}
              onClick={e => {
                e.preventDefault()
                changePage(-1)
              }}
            >
              &lt;
            </button>

            <p>
              {pageNumber}/{numPages}
            </p>
            <button
              className={pageNumber >= numPages ? "disabled" : ""}
              onClick={e => {
                e.preventDefault()
                changePage(1)
              }}
            >
              &gt;
            </button>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Catalogue
